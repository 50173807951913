import React, { useState, useEffect } from "react";

import './style.scss'
import { GetData } from "../../Service";
import SlideProduct from "./SlideProduct";
import RecentClient from "../../container/RecentClient";
import Director from "../../container/Director";
import SlideNews from "../../container/SlideNews";
import MSV from "../../container/MSV";
import Slider from "../../container/Slider";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [t]= useTranslation("global")
  const [dataProduct, setDataProduct] = useState([]);
  const [dataNews, setDataNews] = useState([]);

  useEffect(() => {
    GetData({ setdata: setDataProduct, tipe: 'product' });
    GetData({ setdata: setDataNews, tipe: 'berita' });
  }, []);
  const images = [
    "http://wp.hktifood.com/wp-content/uploads/2023/12/Inaguration1.jpeg",
    "http://wp.hktifood.com/wp-content/uploads/2023/12/Screen-Shot-2023-12-16-at-2.28.11-am.png ",
    "http://wp.hktifood.com/wp-content/uploads/2023/12/Inaguration2.jpeg"
  ];

  return (
    <>
      <Slider
        images={images}
        ContentHome />
      <SlideProduct dataProduct={dataProduct} />
      <div className="brief-information">
        <div className="card-brief">
          <div className="container ">
            <div className="title-brief" data-aos="fade-up">
              <span>{t("koperasi.title")}</span>
            </div>
            <div data-aos="fade-up">
              <p style={{ textAlign: 'justify' }}>{t("koperasi.desc")} </p>
              <p style={{ textAlign: 'justify' }}>{t("koperasi.desc2")}</p>
            </div>
          </div>
        </div>
      </div>
      <MSV />
      <Director removeDescTitle />
      <SlideNews dataNews={dataNews} />
      <RecentClient />
    </>
  );
};

export default Home;
