import React from "react";
import translate from "translate";
import ContentPage from "../ContentPage";
import Header from "../container/Header";
import Footer from "../container/Footer";

const TheLayout = () => {
  const [isTranslated, setIsTranslated] = React.useState(true);
  const translateContent = async () => {
    const contentElements = document.querySelectorAll(".translate");
    for (const element of contentElements) {
      const originalText = element.innerText;
      const translatedText = await translate(originalText, { to: "id" });
      element.dataset.originalText = originalText;
      element.innerText = translatedText;
    }
    setIsTranslated(true);
  };

  const translateContentE = async () => {
    const contentElements = document.querySelectorAll(".translate");
    for (const element of contentElements) {
      const originalText = element.innerText;
      const translatedText = await translate(originalText, { to: "en" });
      element.dataset.originalText = originalText;
      element.innerText = translatedText;
    }
    setIsTranslated(true);
  };

  const resetTranslation = () => {
    const contentElements = document.querySelectorAll(".translate");
    for (const element of contentElements) {
      const originalText = element.dataset.originalText;
      if (originalText) {
        element.innerText = originalText;
      }
    }
    setIsTranslated(false);
  };

  return (
    <div className="layout">
      <Header  isTranslated={isTranslated} translateContent={translateContent} resetTranslation={resetTranslation} />
      <ContentPage />
      <Footer />
    </div>
  );
};

export default TheLayout;
