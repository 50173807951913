import React from "react";
import './style.scss'

import { useTranslation } from "react-i18next";

const Director = ({ removeDescTitle }) => {
    const [t]= useTranslation("global")

    const dataDirector = [
        {
            img: 'http://wp.hktifood.com/wp-content/uploads/2024/01/1.png',
            name: 'Jendral TNI (Purn) Dr. H. Moeldoko, S.I.P., M.A. ',
            mainDesc: 'Ketua Umum',
            desc: 'Himpunan Kerukunan Tani Indonesia'
        },
        {
            img: 'http://wp.hktifood.com/wp-content/uploads/2024/01/3.png',
            name: 'Mayjen TNI (Purn) Winston P. Simanjuntak, S.I.P., M.Si.',
            mainDesc: 'Ketua',
            desc: 'Koperasi HKTI Tamara Bumi Indonesia'
        },
        {
            img: 'http://wp.hktifood.com/wp-content/uploads/2024/01/2.png',
            name: 'Syamsul, SH.',
            mainDesc: 'Ketua',
            desc: 'Koperasi HKTI Tamara Bumi Indonesia Kepulauan Riau'
        }
    ]
    return (
        <>
            <div className="director">
                <div className="card-director">
                    <div className="container">
                        <div className="title-director" data-aos="fade-up">
                            <span className="font-bolder">{t("OURTEAMS")}</span>
                            {/* <span className="font-bolder">Board of Directors</span>
                            {removeDescTitle ? '' : <span> of HKTI Tamara BI Kepri</span>} */}
                        </div>

                        <div className="column-director" data-aos="fade-up">
                            {dataDirector.map((data, idx) => {
                                return (
                                    <div className="content-director" key={`${data.name}-${idx}`}>
                                        <img src={data.img} alt={`${data.name}-${idx}`} className="img-director" />
                                        <div className="name-director">
                                            {data.name}
                                        </div>
                                        <div className="desc-director">
                                            <span className="d-flex justify-content-center">{data.mainDesc}</span>
                                            {data.desc}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Director;
