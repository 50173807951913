import React from "react";
// import AboutMission from "../../assets/img/about-mission.jpeg"
import { useTranslation } from "react-i18next";
import './style.scss'


const MSV = () => {
    const [t] = useTranslation("global")
    return (
        <>
            <div className="msv-style">
                <div className="bgHome" />
                <div className="container">
                    <div className="row" style={{ marginBottom: 100, position: 'relative', zIndex: 2 }}>
                        <div className="col-12 col-lg-6">
                            <div className="title-mision"><span className="font-bolder">{t("visi.title")}</span></div>
                            <div className="desc-mision">
                                <div style={{ textAlign: 'justify' }}>{t("visi.desc")}</div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="title-mision"><span className="font-bolder">{t("misi.title")}</span></div>
                            <div className="desc-mision">
                                <ul>
                                    <li style={{ textAlign: 'justify' }}>{t("misi.Misi1")}</li>
                                    <li style={{ textAlign: 'justify' }}>{t("misi.Misi2")}</li>
                                    <li style={{ textAlign: 'justify' }}>{t("misi.Misi3")}</li>
                                    <li style={{ textAlign: 'justify' }}>{t("misi.Misi4")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ position: 'relative', zIndex: 2 }} >
                        <div className="col-12" data-aos="fade-up">
                            <div className="title-mision"><span className="font-bolder">{t("cooperative.title")}</span></div>
                            <div className="desc-cooperative">
                                <ul>
                                    <li style={{ textAlign: 'justify' }}>{t("cooperative.paraf1")}</li>
                                    <li style={{ textAlign: 'justify' }}>{t("cooperative.paraf2")}</li>
                                    <li style={{ textAlign: 'justify' }}>{t("cooperative.paraf3")}</li>
                                    <li style={{ textAlign: 'justify' }}>{t("cooperative.paraf4")}</li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-12 col-lg-6" style={{ position: 'relative', zIndex: 2 }} data-aos="fade-up">
                            <img src={AboutMission} alt="img-mission" className="img-mission" />
                            <div className="desc-img-mission">
                                <div style={{ width: '177px' }} className="d-flex flex-column align-items-start">
                                    <span>Syamsul</span>
                                    Head Indonesia Director
                                </div>
                                <div style={{ width: '177px' }} className="d-flex flex-column align-items-end">
                                    <span>Syamsul</span>
                                    Head Kepri Director
                                </div>
                            </div>
                            <img src={'http://wp.hktifood.com/wp-content/uploads/2024/01/signature.png} alt="signature" className="signature" />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MSV;
