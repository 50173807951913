import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GetData } from "../../Service";
import { HTMLRenderer } from "../../Service"
import "./style.scss";
import Hero from "../../container/Hero";
import SlideNews from "../../container/SlideNews";
import { useTranslation } from "react-i18next";

const News = () => {
  const [t] = useTranslation("global")
  const [dataNews, setDataNews] = useState([]);
  const [openRead, setOpenRead] = useState(false);
  const [contentProduct, setContentProduct] = useState(1);
  const newsLink = useSelector((state) => state.appState.news);

  useEffect(() => {
    GetData({ setdata: setDataNews, tipe: 'berita' });
  }, []);

  useEffect(() => {
    if (dataNews) {
      dataNews?.forEach((item, idx) => {
        if (newsLink) {
          if (newsLink === `${item.title}-${item.img}`) {
            setContentProduct(item)
          }
        } else if (idx === 0) {
          setContentProduct(item)
        }
      })
    }
  }, [dataNews, newsLink]);

  let cleanText
  if (contentProduct.desc) {
    cleanText = contentProduct.desc.replace(/<img\b[^>]*>/gi, '');
  } else {
    cleanText = contentProduct.desc
  }

  return (
    <>
      <div className={openRead ? '' : 'd-none'}>
        <Hero
          text={{
            first: contentProduct.title,
            bolderLast: true
          }}
          backNews={{
            textNews: t("btn.BacktoNews"),
            onClick: () => setOpenRead(false)
          }}
          imgHero={contentProduct.img}
        />
      </div>
      <div className={`content-full ${openRead ? '' : 'd-none'}`}>
        <div className="container">
          <div className="content-date">{contentProduct.date}</div>
          <div className="content-full-desc ">
            <HTMLRenderer htmlString={contentProduct.desc} />
          </div>
        </div>
      </div>
      <div className={`news-page ${openRead ? 'd-none' : ''}`}>
        <div className="container">
          <div className="news-title" data-aos="fade-up">{ t("nav.news")}</div>
          <div className="news-card" data-aos="fade-up">
            <div className="row">
              <div className="col-12 col-lg-6">
                <img src={contentProduct.img} alt="img-news" className="img-news" />
              </div>
              <div className="col-12 col-lg-6 news-content">
                <div>
                  <div className="news-content-title translate">
                    {contentProduct.title}
                  </div>
                  <div className="news-content-desc translate">
                    <HTMLRenderer htmlString={cleanText} />
                  </div>
                </div>
                <div className="news-content-footer">
                  <div className="time-content-footer">{contentProduct.date}</div>
                  <div className="read-content-footer" onClick={() => setOpenRead(true)}> {t("btn.ReadMore")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlideNews dataNews={dataNews} />
    </>
  );
};

export default News;
