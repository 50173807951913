import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HTMLRenderer } from "../../Service"
import { setProduct } from "../../redux/features/appStateSlice"
import { useTranslation } from "react-i18next";


const SlideProduct = ({ dataProduct }) => {
    const [t] = useTranslation("global")
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activePage, setActivePage] = useState(1);
    const widthInner = window.innerWidth
    let PerPageProduct = 3;

    const showNextPage = () => {
        setActivePage(prevPage => Math.min(prevPage + 1, Math.ceil(dataProduct.length / PerPageProduct)));
    };

    const showPreviousPage = () => {
        setActivePage(prevPage => Math.max(prevPage - 1, 1));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    if (widthInner < 501) PerPageProduct = 1;

    return (
        <div className="product">
            <div className="container">
                <div className="title-product-slide d-lg-none" data-aos="fade-up">
                    <span className="font-bolder">{t("slideProduct.FeaturedProduct")}</span>
                    <span>{t("slideProduct.HKTITamaraBumiIndonesiaKepri")}</span>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className='slide-poduct' data-aos="fade-up">
                            <div
                                className={`paging-arraw ${activePage === 1 ? 'd-none' : ''}`}
                                onClick={showPreviousPage} >
                                <i className="ri-arrow-left-s-line" />
                            </div>
                            <div className='d-flex justify-content-center justify-content-lg-start  w-100'>
                                {dataProduct?.slice((activePage - 1) * PerPageProduct, activePage * PerPageProduct).map((data, idx) => (
                                    <div
                                        className='card-slide-poduct mx-2'
                                        key={`data-slide-poduct-${data.title}-${idx}`}
                                        style={{ opacity: 1 }}
                                        onClick={() => {
                                            dispatch(setProduct(`${data.title}-${data.img}`));
                                            navigate('/product');
                                            scrollToTop();
                                        }}
                                    >
                                        <img src={data.img} alt="Profile slide-poduct" />
                                        <div className="content-slide-product">
                                            <div className='name-slide-poduct translate'>{data.title}</div>
                                            <div className='desc-slide-poduct translate'><HTMLRenderer htmlString={data.desc} /></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`paging-arraw ${activePage === Math.ceil(dataProduct?.length / PerPageProduct) ? 'd-none' : ''}`}
                                onClick={showNextPage}
                            >
                                <i className="ri-arrow-right-s-line" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 desc-product d-none d-lg-block" data-aos="fade-up">
                        <div className="font-bolder"> {t("slideProduct.FeaturedProduct")}</div>
                        <div>{t("slideProduct.HKTITamaraBumiIndonesiaKepri")} </div>
                        <button className="btn btn-custom" onClick={() => {
                            navigate('/product')
                            scrollToTop();
                        }}>
                            {t("btn.ReadMore")}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SlideProduct;
