import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Slider = ({ images, ContentHome }) => {
  const [t] = useTranslation("global")
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const [startX, setStartX] = useState(0);

  const prevImage = () => {
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImage((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToImage = (index) => {
    setCurrentImage(index);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const deltaX = endX - startX;

    if (deltaX > 50) {
      prevImage();
    } else if (deltaX < -50) {
      nextImage();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="slider" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <img src={images[currentImage]} alt="Slider" className={ContentHome ? "slider-image-dark" : "slider-image"} />
      <div className="slider-navigation">
        {images.map((_, index) => (
          <button
            key={index}
            className={`slider-navigation-button ${index === currentImage ? "active" : ""}`}
            onClick={() => goToImage(index)}
          />
        ))}
      </div>
      {ContentHome &&
        <div className="slide-hero">
          <div className="slide-hero-title">{t('hero.home.titleHome')}</div>
          <p className="slide-hero-desc">
            {t('hero.home.descHome.main')}
            <span className="font-bolder"> {t('hero.home.descHome.bold')}</span>
            {t('hero.home.descHome.next')}
          </p>
          <p className="slide-hero-desc">
            <span className="font-bolder"> {t('hero.home.deschHomeSec.bold')}</span>
            {t('hero.home.deschHomeSec.main')}
          </p>
          <div className="btn btn-custom mt-3" onClick={() => navigate('/about')}>
            {t('btn.aboutUs')}
          </div>
        </div>
      }
    </div>
  );
};

export default Slider;