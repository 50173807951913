import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";


const RecentClient = () => {
    const [t]= useTranslation("global")
    return (
        <>
            <div className="client">
                <div className="card-client">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-12 col-lg-3" data-aos="fade-up">
                                <div className="title-client">
                                    <span className="font-bolder">{t("client.Recent")}</span>
                                    <span>{t("client.Client")}</span>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9 content-card-client" data-aos="fade-up">
                                <div className="card-img-client">
                                    <img src={'http://wp.hktifood.com/wp-content/uploads/2024/01/1-1.png'} alt="card-img-client" />
                                </div>
                                <div className="card-img-client">
                                    <img src={'http://wp.hktifood.com/wp-content/uploads/2024/01/2-1.png'} alt="card-img-client" />
                                </div>
                                <div className="card-img-client">
                                    <img src={'http://wp.hktifood.com/wp-content/uploads/2024/01/3-1.png'} alt="card-img-client" />
                                </div>
                                <div className="card-img-client">
                                    <img src={'http://wp.hktifood.com/wp-content/uploads/2024/01/4.png'} alt="card-img-client" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecentClient;
