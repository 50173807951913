import React from "react";
import RecentClient from "../../container/RecentClient";
import Hero from "../../container/Hero";
import { useTranslation } from "react-i18next";


const Contatcs = () => {
  const [t]= useTranslation("global")
  return (
    <>
      <Hero
        text={{
          first: t("Contatcs.hero.first"),
          last: t("Contatcs.hero.last"),
          bolderFrist: true
        }}
        button={{
          link: "https://wa.me/628117771338",
          text: t("Contatcs.hero.btn"),
        }}
        imgHero={'http://wp.hktifood.com/wp-content/uploads/2023/12/hero-contact.png'}
        heroColumn={true}
      />
      <RecentClient />
    </>
  );
};

export default Contatcs;
