import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProduct } from "../../redux/features/appStateSlice"
import { GetData } from "../../Service";
import { HTMLRenderer } from "../../Service"
import "./style.scss";
import Hero from "../../container/Hero";
import { useTranslation } from "react-i18next";

const Product = () => {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const [dataProduct, setDataProduct] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [contentProduct, setContentProduct] = useState(1);
  const productLink = useSelector((state) => state.appState.product);
  const widthInner = window.innerWidth
  let PerPageProduct = 6;

  useEffect(() => {
    GetData({ setdata: setDataProduct, tipe: 'product' });
  }, []);

  useEffect(() => {
    if (dataProduct) {
      dataProduct.forEach((item, idx) => {
        if (productLink) {
          if (productLink === `${item.title}-${item.img}`) {
            setContentProduct(item)
          }
        } else if (idx === 0) {
          setContentProduct(item)
        }
      })
    }
  }, [dataProduct, productLink]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageUrls = contentProduct.slideImg
  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  const imgSlider = imageUrls === undefined || imageUrls.length === 1 ? contentProduct.img : imageUrls[currentImageIndex]

  if (widthInner < 1025) PerPageProduct = 5;
  if (widthInner < 801) PerPageProduct = 3;
  if (widthInner < 501) PerPageProduct = 1;

  return (
    <>
      <Hero
        text={{
          first: t("Product.hero.first"),
          last: t("Product.hero.last"),
          bolderLast: true
        }}
        imgHero={'http://wp.hktifood.com/wp-content/uploads/2024/01/Intersect.png'}
      />
      <div className="product">
        <div className="container">
          <div className="title-product" data-aos="fade-up">
            {t("Product.ListofourProduct")}
          </div>
          <div className="row">
            <div className="col-lg-12" data-aos="fade-up">
              <div className='slide-poduct'>
                <div
                  className={`paging-arraw ${activePage === 1 ? 'd-none' : ''}`}
                  onClick={() => setActivePage(prevPage => Math.max(prevPage - 1, 1))}>
                  <i className="ri-arrow-left-s-line" />
                </div>
                <div className='d-flex justify-content-center justify-content-lg-start w-100'>
                  {dataProduct.slice((activePage - 1) * PerPageProduct, activePage * PerPageProduct).map((data, idx) => {
                    return (
                      <div
                        className={`card-slide-poduct mx-2 ${`${contentProduct.title}-${contentProduct.img}` === `${data.title}-${data.img}` ? 'active' : ''}`}
                        key={`data-slide-poduct-${data.title}-${idx}`}
                        onClick={() => {
                          dispatch(setProduct(`${data.title}-${data.img}`));
                        }}
                        style={{ opacity: 1 }}
                      >
                        <img src={data.img} alt="Profile slide-poduct" />
                        <div className="content-slide-product">
                          <div className='name-slide-poduct translate'>{data.title}</div>
                          <div className='desc-slide-poduct translate'>
                            <HTMLRenderer htmlString={data.desc} />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div
                  className={`paging-arraw ${activePage === Math.ceil(dataProduct.length / PerPageProduct) ? 'd-none' : ''}`}
                  onClick={() => setActivePage(prevPage => Math.min(prevPage + 1, Math.ceil(dataProduct.length / PerPageProduct)))}
                >
                  <i className="ri-arrow-right-s-line" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-product">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6" >
              <div className="img-change-content" data-aos="fade-up">
                {imageUrls?.length > 1 &&
                  <div
                    className={`paging-arraw-content`}
                    onClick={goToPreviousSlide} >
                    <i className="ri-arrow-left-s-line" />
                  </div>
                }

                <img src={imgSlider} alt={`Slide ${currentImageIndex}`} className="img-content" />
                {imageUrls?.length > 1 &&
                  <div
                    className={`paging-arraw-content`}
                    onClick={goToNextSlide}
                  >
                    <i className="ri-arrow-right-s-line" />
                  </div>
                }
              </div>
              <div className="info-content" data-aos="fade-up">
                {contentProduct.additionalInfo?.map((item, idx) => {
                  return (
                    <div className="row py-2" key={`${item.rinciTitle}-${idx}`}>
                      <div className="col-12 col-md-6 info-title translate">{item.rinciTitle}</div>
                      <div className="col-12 col-md-6 info-desc translate">{item.rinciDesc}</div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-12 col-lg-6" data-aos="fade-up">
              <div className="title-content-product translate">{contentProduct.title}</div>
              <div className="desc-content-product translate">
                <HTMLRenderer htmlString={contentProduct.desc} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
