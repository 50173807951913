import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProduct } from "../../redux/features/appStateSlice"
import "remixicon/fonts/remixicon.css";
import "./style.scss";
import { useTranslation } from "react-i18next";



const Footer = () => {
const [t] = useTranslation("global")
const navigate = useNavigate();
const dispatch = useDispatch();

const NavItem = [
  { title: t("nav.home"), route: '/' },
  { title: t("nav.product"), route: '/product' },
  { title: t("nav.news"), route: '/news' },
  { title: t("nav.aboutUs"), route: '/about' },
]
  return (
    <>
      <div className="content-whatsapp">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-12 col-lg-6" data-aos="fade-up">
              <div className="title-whatsapp">{t("whatsapp.title")}</div>
              <div className="desc-whatsapp">
                <span>{t("whatsapp.desc")}</span>
                <span style={{ marginTop: "2rem" }}>{t("whatsapp.desc2")}</span>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-lg-0 mt-5" data-aos="fade-up">
              <div className="call-whatsapp">
                <a href="https://wa.me/628117771338" target="_blank" className="btn btn-custom" rel="noreferrer" >
                  Whatsapp
                </a>
              </div>
            </div>
          </div>


        </div>
      </div>
      <footer className="site-footer">
        <div className="container">
          <div className="content-footer">
            <div className="title-footer">
              <span className="d-flex justify-content-center">HKTI</span>
              Tamara  Bumi Indonesia Kepulauan Riau
            </div>
            <div className="address-footer">
              Oceanic Bliss Cluster Modern Blok A 9 no 02, Sadai, Batam,  Kepulauan Riau, Indonesia
            </div>
            <div>+62 811 7771 338</div>
            <div>kophktitamara.kepri@gmail.com</div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <nav
                role="navigation"
                id="nav-main"
                className="navigation-main d-flex justify-content-center justify-content-lg-start"
              >
                {NavItem.map((data, idx) => {
                  return (
                    <div key={data.title + idx} className="footer-nav" onClick={() => {
                      navigate(data.route);
                      if (data.title === 'Product') dispatch(setProduct(null));
                    }}>{data.title}</div>
                  )
                })}
                <div className="footer-nav" onClick={() => navigate("/contactus")}>{t("btn.ContactUs")}</div>
              </nav>
            </div>

            <div className="col-12 col-lg-4  text-lg-end text-center">
              <i className="ri-instagram-line me-2" />
              <i className="ri-facebook-circle-line" />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
