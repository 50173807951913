import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNews } from "../../redux/features/appStateSlice"
import { useTranslation } from "react-i18next";


const SlideNews = ({ dataNews }) => {
    const [t]= useTranslation("global")
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activePage, setActivePage] = useState(1);
    const widthInner = window.innerWidth
    let PerPageProduct = 4;

    const showNextPage = () => {
        setActivePage(prevPage => Math.min(prevPage + 1, Math.ceil(dataNews?.length / PerPageProduct)));
    };

    const showPreviousPage = () => {
        setActivePage(prevPage => Math.max(prevPage - 1, 1));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    if (widthInner < 1025) PerPageProduct = 3;
    if (widthInner < 501) PerPageProduct = 1;

    return (
        <>
            <div className="news">
                <div className="container">
                    <div className="desc-news d-lg-none" data-aos="fade-up">
                        <div>{t("news.Featured")}</div>
                        <div>{t("news.News")} </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 desc-news d-none d-lg-block" data-aos="fade-up">
                            <div>{t("news.Featured")}</div>
                            <div>{t("news.News")} </div>
                            <button className="btn btn-custom" onClick={() => {
                                scrollToTop();
                                navigate('/news')
                            }}>
                                {t("btn.ReadMore")}
                            </button>
                        </div>
                        <div className="col-lg-9 col-12">
                            <div className='slide-news' data-aos="fade-up">
                                <div
                                    className={`paging-arraw ${activePage === 1 ? 'd-none' : ''}`}
                                    onClick={showPreviousPage} >
                                    <i className="ri-arrow-left-s-line" />
                                </div>
                                <div className='d-flex justify-content-center justify-content-lg-start w-100'>
                                    {dataNews?.slice((activePage - 1) * PerPageProduct, activePage * PerPageProduct).map((data, idx) => (
                                        <div
                                            className='card-slide-news mx-2'
                                            key={`data-slide-news-${data.name}-${idx}`}
                                            style={{ opacity: 1 }}
                                            onClick={() => {
                                                dispatch(setNews(`${data.title}-${data.img}`));
                                                navigate('/news');
                                                scrollToTop();
                                            }}
                                        >
                                            <img src={data.img} alt="img-slide" className="img-slide" />
                                            <div className='name-slide-news translate'>{data.title}</div>
                                            <div className="desc-slide-news">
                                                <div className="time-slied">{data.date}</div>
                                                <div className="read-slide">{t("btn.Read")}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className={`paging-arraw ${activePage === Math.ceil(dataNews?.length / PerPageProduct) ? 'd-none' : ''}`}
                                    onClick={showNextPage}
                                >
                                    <i className="ri-arrow-right-s-line" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default SlideNews;
