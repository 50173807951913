import React from "react";
import "./style.scss";
import RecentClient from "../../container/RecentClient";
import Director from "../../container/Director";
import MSV from "../../container/MSV";
import Slider from "../../container/Slider";
import { useTranslation } from "react-i18next";


const About = () => {
  const [t] = useTranslation("global")
  const images = [
    "http://wp.hktifood.com/wp-content/uploads/2023/12/Inaguration1.jpeg",
    "http://wp.hktifood.com/wp-content/uploads/2023/12/Screen-Shot-2023-12-16-at-2.28.11-am.png ",
    "http://wp.hktifood.com/wp-content/uploads/2023/12/Inaguration2.jpeg"
  ];

  return (
    <>
      <Slider images={images} />
      <div className="brief-information">
        <div className="card-brief">
          <div className="container ">
            <div className="title-brief" data-aos="fade-up">
              <span>{t("koperasi.title")}</span>
            </div>
            <div data-aos="fade-up">
              <p style={{ textAlign: 'justify' }}>{t("koperasi.aboutdesc")} </p>
              <p style={{ textAlign: 'justify' }}>{t("koperasi.aboutdesc2")}</p>
              <p style={{ textAlign: 'justify' }}>{t("koperasi.aboutdesc3")}</p>
              <p style={{ textAlign: 'justify' }}>{t("koperasi.aboutdesc4")}</p>
              <p style={{ textAlign: 'justify' }}>{t("koperasi.aboutdesc5")}</p>
              <p style={{ textAlign: 'justify' }}>{t("koperasi.aboutdesc6")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="content-img-about">
        <div className="container d-flex justify-content-around flex-wrap">
          {images.map((data, idx) => {
            return (
              <img key={data + idx} src={data} alt={`imageitem-${idx}`} className="img-about-us " />
            )
          })}
        </div>
      </div>
      <MSV />
      <div className="address-map">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6" data-aos="fade-up">
              <a href="https://maps.app.goo.gl/ob5hK8e5kjk4FmvZ9" target="_blank" rel="noreferrer">
                <img src={'http://wp.hktifood.com/wp-content/uploads/2024/01/map.png'} alt="img-map" className="img-map" />
              </a>
            </div>
            <div className="col-12 col-md-6" data-aos="fade-up">
              <div className="address-title">
                {t("About.map.AboutUs")}
              </div>
              <div className="address-desc">
                <p>{t("About.map.decs1")}</p>
                <p>{t("About.map.desc2")}</p>
                <p>{t("About.map.desc3")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="costum-bg-about">
        <Director />
        <RecentClient />
      </div>
    </>
  );
};

export default About;
