/* eslint-disable no-cond-assign */
import axios from 'axios';

const MainUrl = process.env.REACT_APP_API_URL_API;

const convertDateFormat = (isoDate) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = new Date(isoDate).toLocaleDateString('id-ID', options);

    return formattedDate;
};

export const HTMLRenderer = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export const GetData = async ({ setdata, tipe, name }) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${MainUrl}wp-json/wp/v2/${tipe}?per_page=100`,
        });

        const data = res.data;
        const newData = data.map(item => {
            const title = item.title.rendered;
            const date = convertDateFormat(item.date);
            const contentString = item.content.rendered;
            const parser = new DOMParser();
            const contentDocument = parser.parseFromString(contentString, "text/html");
            const imageElement = contentDocument.querySelector('img');
            const textElements = contentDocument.querySelectorAll('p');
            const figureElements = contentDocument.querySelectorAll('figure');

            const img = imageElement ? imageElement.src : null;

            const additionalInfoRegex = /\+\+\s*<strong>([^=]+)\s*==\s*([^<]+)<\/strong>/g;
            const additionalInfo = [];
            let match;
            while (match = additionalInfoRegex.exec(contentString)) {
                additionalInfo.push({ rinciTitle: match[1].trim(), rinciDesc: match[2].trim() });
            }
            const figureHTML = Array.from(figureElements).map(element => element.outerHTML);
            const descText = Array.from(textElements).map(element => {
                if (!element.className.includes('justify-text')) {
                    element.className += 'justify-text';
                }
                return element.outerHTML;
            });
            const descAndImg = descText.slice();
            const slideImg = []
            figureHTML.forEach(figure => {
                const insertIndex = 8;
                const figureUrl = figure.match(/src="([^"]+)"/)[1];
                slideImg.push(figureUrl)
                if (figureUrl === img) {
                    descAndImg.splice(insertIndex, 0);
                } else {
                    descAndImg.splice(insertIndex, 0, figure);
                }
            });
            return { title, desc: tipe === 'product' ? descText.join('') : descAndImg.join(''), img, additionalInfo, date, slideImg };
        });
        setdata(newData)
        localStorage.setItem(name, JSON.stringify(newData));
    } catch (error) {
        console.log(error)
    }
};



