import Home from "./pages/Home";
import Contatcs from "./pages/Contatcs";
import Product from "./pages/Product";
import About from "./pages/About";
import News from "./pages/News";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/contactus",
    component: Contatcs,
  },
  {
    path: "/product",
    component: Product,
  },
  {
    path: "/news",
    component: News,
  },
  {
    path: "/about",
    component: About,
  },
];

export default routes;
