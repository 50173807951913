import React from "react";
import './style.scss'


const Hero = ({ button, imgHero, text, heroColumn, backNews, pageHome, pagebutton }) => {
    return (
        <>
            <div className="hero">
                <div className="row">
                    <div className="col-12 col-md-7 p-0 m-0 text-hero">
                        <div className="card-hero">
                            <div className="title-hero" data-aos="fade-up">
                                <div className={heroColumn ? 'text-title' : ''}>
                                    <span className={`${text.bolderFrist ? 'font-bolder' : ''}`}>{text.first}</span>
                                    <span className={`${text.bolderLast ? 'font-bolder' : ''}`}>{text.last}</span>
                                </div>
                                {button &&
                                    <a
                                        href={button.link}
                                        className="btn btn-custom"
                                        data-aos="fade-up"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {button.text}
                                    </a>}
                                {backNews &&
                                    <div className="back-news" onClick={backNews.onClick}>
                                        <i className="ri-arrow-left-s-line" /> <span>{backNews.textNews}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {pageHome ?
                        <div className="col-12 col-md-5 d-none d-md-block" style={{ background: '#fff' }} data-aos="fade-up">
                            <img src={imgHero} alt="img-hero" className="img-hero-page" />
                        </div>
                        :
                        <div className="col-12 col-md-5 d-none d-md-block" data-aos="fade-up">
                            <img src={imgHero} alt="img-hero" className="img-hero" />
                        </div>
                    }

                </div>
            </div>
        </>
    );
};

export default Hero;
